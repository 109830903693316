const formatAssetNumberValue = (value) => {
    // some error condition
    if (value < 0) {
        return "N/A";
    }

    return value;
};

const formatAssetDuration = (minutes) => {
    let retval = "";

    // some error condition
    if (minutes < 0) {
        return "N/A";
    }

    // round to 1 decimal

    if (minutes < 60) {
        minutes = Math.round(10.0 * minutes) / 10.0;
        retval += minutes + " min";
    } else {
        let hours = minutes / 60.0;
        hours = Math.round(10.0 * hours) / 10.0;
        retval += hours + " h";
    }

    return retval;
};

const formatAssetPercentage = (percent) => {
    // console.log("percent", percent);

    let retval = "";

    // some error condition
    if (percent < 0) {
        return "N/A";
    }

    // round to 1 decimal
    percent = Math.round(10.0 * percent) / 10.0;
    retval += percent + "%";

    return retval;
};

// returns 100 * val1 / val2 % as string
const formatAssetRatioAsPercentage = (val1, val2) => {
    // console.log("val1, val2", val1, val2);

    let retval = "";

    // some error condition
    if (val1 < 0 && val2 < 0) {
        return "N/A";
    }

    if (Number(val1) === 0.0 && Number(val2) === 0.0) {
        // if either value is 0, just set the result percentage to zero, even in the case of 0/0
        return "0%";
    }

    // round to 1 decimal
    let percent = Math.round((1000.0 * val1) / val2) / 10.0;
    retval += percent + "%";

    return retval;
};

// asset is the current asset
const getAssetNameFromCurrentAsset = (assetId, asset) => {
    // console.log("current asset", asset);
    if (!asset || !asset.name) {
        return "Asset " + assetId;
    }

    return asset.name ? asset.name.replace(/<[^>]*>?/gm, "") : "";
};

export { formatAssetNumberValue, formatAssetDuration, formatAssetPercentage, formatAssetRatioAsPercentage, getAssetNameFromCurrentAsset };
