import React, { useEffect, useState, useMemo } from "react";
import { useTable, useSortBy } from "react-table";
import Loader from "react-loader-spinner";
import { useHistory } from "react-router-dom";
// import { useMyContext } from "../../contexts/StateHolder";
// import AssetsItem from "./AssetsItem";

//import makeData from "./makeData";

import * as classes from "./AssetListPerAccount.module.css";

// import { getLatestAssets } from "../../../scripts/dataHandlers";

// import { getKpiValuesFromOpenSearch } from "../../scripts/openSearchHandler";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

export default function AssetListPerAccount(props) {
    const history = useHistory();

    const [showLoader1, setShowLoader1] = useState(true);

    const organizationId = props.organizationId;
    const secret = props.secret;
    const assetList = props.assetList;

    // console.log("latestAssets", latestAssets);
    // console.log("assetList", assetList);

    // console.log(props);

    // Adapted from https://react-table.tanstack.com/docs/examples/basic
    // The above link points now to v8 and typescript code
    // This was v7, so adapted from https://react-table-v7.tanstack.com/docs/examples/basic

    // https://codesandbox.io/s/github/tannerlinsley/react-table/tree/v7/examples/sorting?from-embed=&file=/src/App.js:1399-1662

    const makeHeader = (headerName) => {
        return [
            {
                Header: headerName,
                columns: [
                    {
                        Header: "",
                        accessor: "index",
                    },
                    {
                        Header: "ID",
                        accessor: "assetId",
                    },
                    {
                        Header: "Asset title",
                        accessor: "title",
                    },
                    {
                        Header: "Video presets",
                        accessor: "videoFiles",
                    },
                    {
                        Header: "Subtitles",
                        accessor: "subtitleAssets",
                    },
                    {
                        Header: "Thumbnail image",
                        accessor: "thumbnailImages",
                    },
                    {
                        Header: "Banner image",
                        accessor: "bannerImages",
                    },
                    {
                        Header: "DVD cover image",
                        accessor: "coverImages",
                    },
                ],
            },
        ];
    };

    // const assetClickHandler = (el) => {
    //     setCurrentAsset(el);

    //     // console.log("setCurrentAsset", el);

    //     history.push(`/analytics/vod/asset/${el.id}/overview`);
    // };

    const getAssetTitle = (asset) => {
        //console.log("asset", asset);
        let title = asset.title;
        let ret = "";
        if (title.fi_FI) {
            ret = title.fi_FI;
        } else if (title.en_US) {
            ret = title.en_US;
        }

        ret = ret.replace(/<[^>]*>?/gm, "");

        return ret;
    };

    const columns1 = useMemo(() => makeHeader(" "), []);

    const memoFunction1 = () => {
        if (assetList !== null) {
            setShowLoader1(false);
        }

        return makeItems(assetList);
    };

    // const memoFunction2 = () => {
    //     if (popularAssets !== null) {
    //         setShowLoader2(false);
    //     }

    //     return makeItems(popularAssets, popularAssetsTotalPlaysVOD, popularAssetsTotalUniqueViewersVOD, popularAssetsTotalWatchedMinutesVOD);
    // };

    const makeItems = (assets) => {
        // console.log("assets", assets);
        // console.log("assets  length", assets.length);

        if (assets === null) {
            // No assets yet, so just return an empty array, which means the table is empty.
            return [];
        }

        const result = assets.map((asset, index) => {
            // console.log("thumb", asset.thumbnailSmall);
            let item = {
                index: index + 1,
                assetId: asset.assetId,
                title: getAssetTitle(asset),
                videoFiles: asset.videoFiles,
                subtitleAssets: asset.subtitleAssets,
                thumbnailImages: asset.thumbnailImages,
                bannerImages: asset.bannerImages,
                coverImages: asset.coverImages,
                // thumbnailImages: (
                //     <div className={classes.AssetInfo}>
                //         {asset.thumbnailImages > 0 ? (
                //             <div style={{ color: "green" }} className={classes.icon}>
                //                 <FontAwesomeIcon icon={faCheck} size="1x" />
                //             </div>
                //         ) : (
                //             <div style={{ color: "red" }} className={classes.icon}>
                //                 <FontAwesomeIcon icon={faTimes} size="1x" />
                //             </div>
                //         )}
                //     </div>
                // ),
                // bannerImages: (
                //     <div className={classes.AssetInfo}>
                //         {asset.bannerImages > 0 ? (
                //             <div style={{ color: "green" }} className={classes.icon}>
                //                 <FontAwesomeIcon icon={faCheck} size="1x" />
                //             </div>
                //         ) : (
                //             <div style={{ color: "red" }} className={classes.icon}>
                //                 <FontAwesomeIcon icon={faTimes} size="1x" />
                //             </div>
                //         )}
                //     </div>
                // ),
                // coverImages: (
                //     <div className={classes.AssetInfo}>
                //         {asset.coverImages > 0 ? (
                //             <div style={{ color: "green" }} className={classes.icon}>
                //                 <FontAwesomeIcon icon={faCheck} size="1x" />
                //             </div>
                //         ) : (
                //             <div style={{ color: "red" }} className={classes.icon}>
                //                 <FontAwesomeIcon icon={faTimes} size="1x" />
                //             </div>
                //         )}
                //     </div>
                // ),
            };
            return item;
        });

        return result;
    };

    // //const data = useMemo(() => makeData(3), [latestAssets]);
    const assetListData = useMemo(
        memoFunction1,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [assetList]
    );

    const Table = ({ columns, data, header }) => {
        // Use the state and functions returned from useTable to build your UI
        const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
            {
                columns,
                data,
            },
            useSortBy
        );

        // console.log("data", data);

        // Render the UI for your table
        return (
            <div>
                <h2>{header}</h2>
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render("Header")}
                                        {/* Add a sort direction indicator */}
                                        <span>{column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}</span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr className={classes.resultItemTitle} {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    };

    return (
        <>
            {/* <div className={classes.Assets_dashboard_title}>
                {props.showLoader && (
                    <div className={classes.Assets_loader}>
                        {" "}
                        <Loader type="Oval" color="#394855" height={300} width={300} />
                    </div>
                )}
            </div>{" "}
            <div className={classes.Assets_dashboard_title}>
                {" "}
                {latestAssets.length > 0 && (
                    <AssetsItem
                        assets={latestAssets}
                        // .filter((event) => event.startTime < startDay && event.startTime > monthAgo)
                        // .sort((a, b) => b.startTime - a.startTime)}
                        heading={"Latest and most popular video assets"}
                        organizationId={props.organizationId}
                        showNoImage={true}
                    />
                )}{" "}
            </div>{" "} */}
            <div className={classes.row}>
                <div className={classes.column}>
                    <Table columns={columns1} data={assetListData} header={"Asset List"} />
                    {showLoader1 && (
                        <div className={classes.Assets_loader}>
                            {" "}
                            <Loader type="Oval" color="#394855" height={300} width={300} />
                        </div>
                    )}
                </div>
                {/* <div className={classes.column}>
                    <Table columns={columns2} data={popularAssetsData} header={"Most Popular"} />
                    {showLoader2 && (
                        <div className={classes.Assets_loader}>
                            {" "}
                            <Loader type="Oval" color="#394855" height={300} width={300} />
                        </div>
                    )}
                </div> */}
            </div>
        </>
    );
}
