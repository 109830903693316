import Axios from "axios";

import { betterCreateToken, createToken, createAssetIdToken } from "./utils";

// Get all category names and id:s
/*const getCategories = async (organizationId, key, lang) => {
  try {
    const version = '05';
    console.log(organizationId, key, lang);

    const catResponse = await Axios.get(
      `https://suite.icareus.com/api/archive?action=getGroups&organizationId=${organizationId}&languageId=${lang}&token=${key}&groupTypeName=Folders&version=${version}`
    );
    console.log(catResponse, 'cat');

    return catResponse.data.groupItems;
  } catch (err) {
    console.log(err);
  }
};*/
/*const getCategoriesBeta = async (organizationId, key, lang) => {
  try {
    const version = '05';
    console.log(organizationId, key, lang);

    const catResponse = await Axios.get(
      `https://suite.icareus.com/api/archive?action=getGroups&organizationId=${organizationId}&languageId=${lang}&token=${key}&groupTypeName=Folders&version=${version}`
    );
    console.log(catResponse, 'cat');
    // let allCategoryID = await catResponse.data.groupItems.map((el) => el.id);
    let allCategoryID = createGroupItemId(catResponse.data.groupItems);
    let token = await createToken(organizationId, allCategoryID, key);

    let allAssets = await getAssets(organizationId, allCategoryID, token, lang);
    console.log(allAssets, 'allAssets');

    return [catResponse.data.groupItems, allAssets];
  } catch (err) {
    console.log(err);
  }
};*/

// Get assets that are listed in groupItemId -string
/*const getAssets = async (organizationId, groupItemId, token, lang) => {
  // 387253,350082,70302,79608,5822973,117322824;
  try {
    console.log(organizationId, groupItemId, token, lang, 'getAssets Code');
    const itemResponse = await Axios.get(
      `https://suite.icareus.com/api/publishing/getAssets?version=05&organizationId=${organizationId}&languageId=${lang}&groupItemId=${groupItemId}&token=${token}`
    );
    console.log('getAssets: ', itemResponse);
    return itemResponse.data.assets;
  } catch (err) {
    console.log(err);
  }
};*/
/*const getAssetsBeta = async (organizationId, groupItemId, token, lang) => {
  // 387253,350082,70302,79608,5822973,117322824;
  try {
    console.log(organizationId, groupItemId, token, lang, 'getAssets Code');
    const itemResponse = await Axios.get(
      `https://suite.icareus.com/api/publishing/getAssets?version=05&organizationId=${organizationId}&languageId=${lang}&groupItemId=${groupItemId}&token=${token}`
    );
    console.log('getAssets: ', itemResponse);
    return itemResponse.data.assets;
  } catch (err) {
    console.log(err);
  }
};*/

// Get items that are listed in groupItemId -string
/*const getSimiliar = async (organizationId, groupItemId, token, lang, limit) => {
  console.log(organizationId, 'similar organizationId');
  try {
    let limitValue;
    limitValue = limit ? limit : 10;
    const itemResponse = await Axios.get(
      `https://suite.icareus.com/api/publishing/getAssets?version=05&organizationId=${organizationId}&orderBy=publishStart&limit=${limitValue}&languageId=${lang}&groupItemId=${groupItemId}&token=${token}`
    );
    console.log('getSimiliar: ', itemResponse);
    return itemResponse.data.assets;
  } catch (err) {
    console.log(err);
  }
};*/
// Get items that are listed in groupItemId -string
/*const getSimiliarBeta = async (
  organizationId,
  groupItemId,
  token,
  lang,
  limit
) => {
  console.log(organizationId, 'similar organizationId');
  try {
    let limitValue;
    limitValue = limit ? limit : 10;
    const itemResponse = await Axios.get(
      `https://suite.icareus.com/api/publishing/getAssets?version=05&organizationId=${organizationId}&orderBy=publishStart&limit=${limitValue}&languageId=${lang}&groupItemId=${groupItemId}&token=${token}`
    );
    console.log('getSimiliar: ', itemResponse);
    return itemResponse.data.assets;
  } catch (err) {
    console.log(err);
  }
};*/

// Get items that are listed in groupItemId -string
/*const getAsset = async (organizationId, assetId, token, lang) => {
  try {
    const itemResponse = await Axios.get(
      `https://suite.icareus.com/api/publishing/getAsset?version=03&organizationId=${organizationId}&userId=0&languageId=${lang}&assetId=${assetId}&token=${token}`
    );
    console.log('getAsset: ', itemResponse);
    return itemResponse.data;
  } catch (err) {
    console.log(err);
  }
};*/

// Get serie episodes
/*const getEpisodes = async (organizationId, groupItemId, token, lang) => {
  try {
    const episodeResponse = await Axios.get(
      `https://suite.icareus.com/api/publishing?action=getAssets&version=05&organizationId=${organizationId}&userId=0&languageId=${lang}&token=${token}&groupItemId=${groupItemId}&series=false`
    );
    console.log('getEpisodes: ', episodeResponse);
    return episodeResponse.data.assets;
  } catch (err) {
    console.log(err);
  }
};*/

// Get promoted content
/*const getPromo = async (
  organizationId,
  groupItemId,
  token,
  lang,
  limitValue
) => {
  let limit = limitValue ? limitValue : 3;
  try {
    const promoResponse = await Axios.get(
      `https://suite.icareus.com/api/publishing?action=getAssets&version=05&organizationId=${organizationId}&userId=0&languageId=${lang}&orderBy=publishStart&limit=${limit}&token=${token}&groupItemId=${groupItemId}&series=false`
    );
    console.log('getPromo: ', promoResponse);
    return promoResponse.data.assets;
  } catch (err) {
    console.log(err);
  }
};*/

// Get banner items
/*const getBanner = async (organizationId, groupItemId, token, lang) => {
  try {
    const bannerResponse = await Axios.get(
      `https://suite.icareus.com/api/publishing?action=getBanners&version=05&organizationId=${organizationId}&userId=0&languageId=${lang}&token=${token}&groupItemId=${groupItemId}&series=false`
    );
    console.log('getBanner: ', bannerResponse);
    return bannerResponse.data.assets;
  } catch (err) {
    console.log(err);
  }
};*/

// Authenticate user
/*const authenticateUser = async (organizationId, email, password) => {
  try {
    const authResponse = await Axios.get(
      `https://suite.icareus.com/api/login?action=authenticate&organizationId=${organizationId}&eMail=${email}&password=${password}`
    );
    console.log('Authentication: ', authResponse);
    return authResponse;
  } catch (err) {
    console.log(err);
  }
};*/

// Register user
/*const registerUser = async (organizationId, email, password) => {
  
      //api/register -> action = addSubscriber, muut parametrit: organizationId, firstName, lastName,
      //screenName, languageId, password, countryId, regionId, address1, address2, postalCode, cityName, phoneNumber, emailAddress
      
  try {
    const registerResponse = await Axios.get(
      `https://suite.icareus.com/api/register?action=addSubscriber&organizationId=${organizationId}&emailAddress=${email}&password=${password}`
    );
    console.log('Register: ', registerResponse);
    return registerResponse;
  } catch (err) {
    console.log(err);
  }
};*/

// Get all events
/*const getAllEvents = async (organizationId, limit, from, to) => {
  try {
    let url;
    if (categoryId) {
      url = `https://suite.icareus.com/api/events?action=getEvents&version=02&organizationId=${organizationId}&categoryIds=${categoryId}&end=${limit}`;
    } else {
      url = `https://suite.icareus.com/api/events?action=getEvents&version=02&organizationId=${organizationId}&end=${limit}`;
    }
    if (from && !to) {
      url = `${url}&from=${from}`;
    }

    if (from && to) {
      url = `${url}&from=${from}&to=${to}`;
    }

    const res = await Axios.get(url);

    return res;
  } catch (error) {
    console.log(error);
  }
};*/
// Get all events
/*const getAllEventsBeta = async (
  organizationId,
  categoryId,
  limit,
  from,
  to
) => {
  try {
    let url;
    if (categoryId) {
      url = `https://suite.icareus.com/api/events?action=getEvents&version=02&organizationId=${organizationId}&categoryIds=${categoryId}&end=${limit}`;
    } else {
      url = `https://suite.icareus.com/api/events?action=getEvents&version=02&organizationId=${organizationId}&end=${limit}`;
    }
    if (from && !to) {
      url = `${url}&from=${from}`;
    }

    if (from && to) {
      url = `${url}&from=${from}&to=${to}`;
    }

    const res = await Axios.get(url);

    return res;
  } catch (error) {
    console.log(error);
  }
};*/

export const getSingleEvent = async (organizationId, eventId) => {
    try {
        let url = `https://suite.icareus.com/api/events`;
        const params = {
            action: "getEvent",
            version: "02",
            organizationId,
            eventId,
        };
        const res = await Axios.get(url, { params });

        // console.log(res);
        return res;
    } catch (error) {
        console.log(error);
    }
};

// get upcomingEvents based on currentTime
export const getEvents = async (organizationId, end, from, to) => {
    try {
        //const currentTime = Date.now();
        let url = `https://suite.icareus.com/api/events`;

        const params = {
            action: "getEvents",
            version: "02",
            organizationId,
            end,
            from,
            to,
        };

        const res = await Axios.get(url, { params });
        // console.log(`res upcoming events`, res);

        return res;
    } catch (error) {
        console.log(error);
    }
};

export const getSingleAsset = async (organizationId, secret, assetId) => {
    try {
        let languageId = "en_US";
        let token = createAssetIdToken(organizationId, assetId, languageId, secret);
        let url = "https://suite.icareus.com/api/publishing";

        const params = {
            action: "getAsset",
            version: "03",
            organizationId,
            assetId,
            token,
            languageId,
        };
        const res = await Axios.get(url, { params });

        // console.log(`resSingleAsset`, res);
        return res;
    } catch (error) {
        console.log(error);
    }
};

// Will be documented on
// https://helpdesk.icareus.com/portal/en/kb/articles/publishing-api
// in the future.

// # get latest assets

// fetches the latest assets for the specified organization (amount of assets to fetch determined by the 'limit' parameter)

// ## call

// `https://suite.icareus.com/api/publishing?organizationId=1909009&languageId=en_US&limit=5&onlyVideos=true&action=getLatestAssets&token=0561c185571232e9058da726819a9f64dcb25c8164`

// ## params

// - action: getLatestAssets
// - organizationId: Suite organization id
// - languageId: language id (default: en_US)
// - limit: how many 'latest assets' to fetch (default: 16)
// - onlyVideos: if true, will not fetch 'podcast' assets (default: true)
// - userId: suite user id (optional)
// - referenceId: asset referenceId, if you don't know what this is just leave the parameter out (not needed in case of react apps)
// - token: token (generated the same way as the token for 'getAssets' - with the assumption that 'groupItemIds' is always an empty string, and version is any string of 2 characters)

// > Can check how the token is generated from getAssets documentation

// const getLatestAssets = async (organizationId, end, from, to) => {
//     try {
//         //let token = await createToken(organizationId, allCategoryID, key);

//         //const currentTime = Date.now();
//         // let url = "https://suite.icareus.com/api/publishing?organizationId=1909009&languageId=en_US&limit=5&onlyVideos=true&action=getLatestAssets&token=0561c185571232e9058da726819a9f64dcb25c8164";
//         let url = "https://suite.icareus.com/api/publishing";
//         // let url = `https://suite.icareus.com/api/events`;

//         const params = {
//             action: "getLatestAssets",
//             version: "02",
//             organizationId: 1909009,
//             language: "en_US",
//             limit: 50,
//             onlyVideos: true,
//             token: "0561c185571232e9058da726819a9f64dcb25c8164",
//             end,
//             from,
//             to,
//         };

//         const res = await Axios.get(url, { params });
//         console.log(`resLatestAssets`, res);

//         return res;
//     } catch (error) {
//         console.log(error);
//     }
// };

// const getIpInfo = async () => {
//     try {
//         let url = "https://ipinfo.io";

//         const params = {};

//         const res = await Axios.get(url, { params });
//         console.log(`resIpInfo`, res);

//         return res;
//     } catch (error) {
//         console.log(error);
//     }
// };

export const getLatestAssets = async (organizationId, secret, limit, userId) => {
    try {
        let token = createToken(organizationId, "", secret);
        let url = "https://suite.icareus.com/api/publishing";

        const params = {
            action: "getLatestAssets",
            version: "02",
            organizationId,
            language: "en_US",
            limit,
            onlyVideos: true,
            token,
        };
        if (userId) {
            params.userId = userId;
        }

        // console.log(`params`, params);
        const res = await Axios.get(url, { params });
        // console.log(`resLatestAssets`, res);

        return res;
    } catch (error) {
        console.log(error);
    }
};

// TODO: use this

// # Asset Search API
// ## Search Assets
// Searches through all **published** and **publish ready** assets for the specified Organization.

// Additionally searches through all group items of type **series** and returns a separate array of those.

// **Path**:
// /api/search/assets

// ### Query Parameters
// | Name | Type | Requirement | Description |
// |----|----|----|----|
// | organizationId | Number | **Mandatory** | Suite Organization ID |
// | keyword | String | **Mandatory** | Search keyword |
// | languageId | String | Optional | Language id, default: 'en_US'. Determines what language is used for getting asset/group item metadata. |
// | includeSeasons | Boolean | Optional | Default: false. By default only group items of level 1 (i.e. top-level group items) are searched. If this parameter is set to false, all group items are included in the search instead.
// | userId | Number | Optional | Default: 0, Suite user ID, used for getting asset package information |

// ### Success Response
// **HTTP Status Code:** 200
// A successfull response will always be a JSON Object that contains the key `"status": "ok"`

// ``` json
// {
// 	"status": "ok",
// 	"assets": [
// 		{
// 			"id": 0,
// 			"name": "string"
// 			...
// 		},
// 		{
// 			"id": 0,
// 			"name": "string"
// 			...
// 		}
// 	],
// 	"series": [
// 		{
// 			"id": 0
// 			"title": "string"
// 			...
// 		},
// 		{
// 			"id": 0
// 			"title": "string"
// 			...
// 		}
// 	]
// }
// ```

// ### Error Response
// **HTTP Status Code:** 200

// ``` json
// {
// 	"status": "error",
// 	"message": "Information about what went wrong."
// }
// ```

export const getSearchAssets = async (organizationId, keyword, userId) => {
    try {
        let url = "https://suite.icareus.com/api/search/assets";

        const params = {
            action: "searchAssets",
            keyword,
            organizationId,
            //languageId: "en_US",
        };
        if (userId) {
            params.userId = userId;
        }

        const res = await Axios.get(url, { params });
        // console.log(`resSearchAssets`, res);

        return res;
    } catch (error) {
        console.log(error);
    }
};

// # Created Assets API

// This API is used to fetch the number of assets created in a specified range of time. Created assets can optionally also be fetched based on the `userId` of the asset creator.

// **Path:**
// `/api/analytics`

// ## Query Parameters

// | Name           | Type   | Requirement       | Description                                                      |
// |----------------|--------|-------------------|------------------------------------------------------------------|
// | action         | String | **Mandatory**     | Value should be: 'getCreated'                              |
// | organizationId | Number | **Mandatory**     | Suite Organization ID                                            |
// | token          | String | _do we need one?_ | **NOT IMPLEMENTED**                                              |
// | from           | String | _Optional_        | Beginning date to fetch created assets from. Format "yyyy-MM-dd" |
// | to             | String | _Optional_        | End date to fetch created assets from. Fromat "yyyy-MM-dd"       |
// | userId         | Number | _Optional_        | If provided, only assets created by this `userId` are listed     |

// > If `from` and `to` are omitted from the query, fetches created assets from the current day by default. **better default?**

// > **NOTE ON TOKEN:** If there's going to be a token, it's _probably_ going to just be the same token that is used for `getAssets`, where `groupItemIds` is always just assumed to be empty.

// ## Response

// ### Success

// ```json
// {
//     "from": "2020-01-03",
//     "to": "2022-01-18",
//     "status": "ok",
//     "userId": 10703,
//     "data": {
//         "2022-01-10": 1,
//         "2021-09-27": 2,
//         "2021-07-02": 3,
//         "2022-01-18": 1,
//         "2021-09-21": 5,
//         "2021-09-22": 1,
//         "2020-09-23": 1,
//         "2021-09-23": 3,
//         "2021-07-01": 3,
//         "2021-06-30": 3,
//         "2021-05-11": 1
//     }
// }
// ```

// ### Error

// ```json
// {
//     "status": "error",
//     "message": "information about what went wrong"
// }
// ```

export const getCreatedAssets = async (organizationId, secret, from, to, userId) => {
    let token = createToken(organizationId, "", secret);

    try {
        let url = "https://suite.icareus.com/api/analytics";

        let params = {
            action: "getCreated",
            organizationId,
            token,
            from,
            to,
        };
        if (userId) {
            params.userId = userId;
        }

        // console.log("params", params);

        const res = await Axios.get(url, { params });
        // console.log("retCreatedAssets", res);

        // console.log("=============> created data", res.data);

        return res;
    } catch (error) {
        console.log(error);
    }
};

export const getPublishedAssets = async (organizationId, secret, from, to, userId) => {
    let token = createToken(organizationId, "", secret);

    try {
        let url = "https://suite.icareus.com/api/analytics";

        let params = {
            action: "getPublished",
            organizationId,
            token,
            from,
            to,
        };
        if (userId) {
            params.userId = userId;
        }

        // console.log("params", params);

        const res = await Axios.get(url, { params });
        // console.log("retPublishedAssets", res);

        // console.log("=============> published data", res.data);

        return res;
    } catch (error) {
        console.log(error);
    }
};

/*const getEventsCategoriesBeta = async (organizationId) => {
  try {
    const res = await Axios.get(
      `https://suite.icareus.com/api/events?action=getCategories&version=02&organizationId=${organizationId}`
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};*/

/*const getEventsCategories = async (organizationId) => {
  try {
    const res = await Axios.get(
      `https://suite.icareus.com/api/events?action=getCategories&version=02&organizationId=${organizationId}`
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};*/

/*const getChannels = async (organizationId, secret) => {
  try {
    // const res = await Axios.get(
    //   `https://suite.icareus.com/api/organization?action=getOrganization&version=04&organizationId=69922&includeSubOrganizations=true`
    // );
    let token = secret;
    const res = await Axios.get(
      `https://suite.icareus.com/api/organization?action=getOrganization&version=04&organizationId=${organizationId}&includeSubOrganizations=true&token=${token}`
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};*/
/*const getChannelsBeta = async (organizationId, secret) => {
  try {
    // const res = await Axios.get(
    //   `https://suite.icareus.com/api/organization?action=getOrganization&version=04&organizationId=3409909&includeSubOrganizations=true`
    // );
    let token = secret ? secret : 'tNtSx9xU06';
    const res = await Axios.get(
      `https://suite.icareus.com/api/organization?action=getOrganization&version=04&organizationId=${organizationId}&includeSubOrganizations=true&token=${token}`
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};*/
/*const getPlaybackUrlBeta = async (organizationId, lang, token, assetId) => {
  try {
    const res = await Axios.get(
      `https://suite.icareus.com/api/publishing/getAssetPlaybackUrls?version=03&organizationId=${organizationId}&languageId=${lang}&assetId=${assetId}&token=${token}`
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};*/
// Get all category names and id:s
/*const getSubCategories = async (organizationId, key, lang, groupItemId) => {
  try {
    const catResponse = await Axios.get(
      `https://suite.icareus.com/api/archive?action=getGroups&organizationId=${organizationId}&userId=0&languageId=${lang}&token=${key}&groupItemId=${groupItemId}`
    );
    console.log('getCategories: ', catResponse);
    return catResponse.data.groupItems;
  } catch (err) {
    console.log(err);
  }
};*/

export const getAssetUtilityAssetInfo = async (organizationId, secret, limit) => {
    try {
        let token = betterCreateToken("" + organizationId, secret);
        let url = "https://suite.icareus.com/api/assetutility";

        const params = {
            action: "getAssetInfo",
            version: "01",
            organizationId,
            token,
            limit,
        };

        // console.log(`params`, params);
        const res = await Axios.get(url, { params });

        console.log(`resAssetInfo`, res);

        return res;
    } catch (error) {
        console.log(error);
    }
};
