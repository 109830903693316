import React, { useEffect, useState, useMemo } from "react";
import { useTable } from "react-table";
import Loader from "react-loader-spinner";
import { useHistory } from "react-router-dom";
// import { useMyContext } from "../../contexts/StateHolder";
// import AssetsItem from "./AssetsItem";
import AssetText2 from "./AssetText2";
import { convertDuration } from "../../scripts/utils";
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

//import makeData from "./makeData";

import * as classes from "./AssetsTable.module.css";

// import { getLatestAssets } from "../../../scripts/dataHandlers";

import { getKpiValuesFromOpenSearch } from "../../scripts/openSearchHandler";

export default function AssetsTable(props) {
    // console.log("AssetsTable props", props);

    // const { latestAssets, setLatestAssets } = useMyContext();
    // const { setCurrentAsset } = useMyContext();
    const history = useHistory();
    // const [showLoader, setShowLoader] = useState(false);
    const [showLoader1, setShowLoader1] = useState(true);

    const [latestAssetsTotalPlaysVOD, setLatestAssetsTotalPlaysVOD] = useState([]);
    const [latestAssetsTotalUniqueViewersVOD, setLatestAssetsTotalUniqueViewersVOD] = useState([]);
    // const [latestAssetsTotalWatchedMinutesVOD, setLatestAssetsTotalWatchedMinutesVOD] = useState([]);

    // const startDay = moment().startOf("day");
    // const endDay = moment().endOf("day");
    // const monthAgo = moment().startOf("day").subtract(30, "days");

    // TODO: give maximum number of assets to show a.k.a. limit as a prop

    const organizationId = props.organizationId;
    // const secret = props.secret;
    // const userId = props.userId;
    const latestAssets = props.assets;

    const clickhandler = (el) => {
        // setCurrentAsset(el);

        // console.log("setCurrentAsset", el);

        history.push(`/analytics/vod/asset/${el.id}/overview`);
    };

    // console.log(props);

    // Adapted from https://react-table.tanstack.com/docs/examples/basic

    const makeHeader = (headerName) => {
        return [
            {
                Header: headerName,
                columns: [
                    {
                        Header: "",
                        accessor: "index",
                    },
                    {
                        Header: "",
                        accessor: "thumbnail",
                    },
                    {
                        Header: "Video Details",
                        accessor: "name",
                    },
                    {
                        Header: "Plays",
                        accessor: "plays",
                    },
                    {
                        Header: "Unique Viewers",
                        accessor: "uniqueViewers",
                    },
                    // {
                    //     Header: "Avg. Percentage Watched",
                    //     accessor: "avgPercentageWatched",
                    // },
                ],
            },
        ];
    };

    // const assetClickHandler = (el) => {
    //     setCurrentAsset(el);

    //     // console.log("setCurrentAsset", el);

    //     history.push(`/analytics/vod/asset/${el.id}/overview`);
    // };

    const columns1 = useMemo(() => makeHeader("Search results"), []);

    // //const data = useMemo(() => makeData(3), [latestAssets]);
    const data = useMemo(() => {
        // console.log("latest assets", latestAssets);
        // console.log("latest assets  length", latestAssets.length);
        if (latestAssets.length > 0) {
            setShowLoader1(false);
        }

        const getPlays = (assetId) => {
            if (!latestAssetsTotalPlaysVOD) {
                return 0;
            }

            let s = "" + assetId;
            let v = latestAssetsTotalPlaysVOD.find((a) => {
                return a.assetId === s;
            });

            if (v) {
                return v.value;
            }

            return 0;
        };

        const getUniqueViewers = (assetId) => {
            if (!latestAssetsTotalUniqueViewersVOD) {
                return 0;
            }

            let s = "" + assetId;
            let v = latestAssetsTotalUniqueViewersVOD.find((a) => {
                return a.assetId === s;
            });

            if (v) {
                return v.value;
            }

            return 0;
        };

        // const getAvgPercentageWatched = (assetId, duration) => {
        //     if (!latestAssetsTotalPlaysVOD) {
        //         return 0;
        //     }
        //     if (!latestAssetsTotalWatchedMinutesVOD) {
        //         return 0;
        //     }

        //     let s = "" + assetId;
        //     let pv = latestAssetsTotalPlaysVOD.find((a) => {
        //         return a.assetId === s;
        //     });
        //     let mv = latestAssetsTotalWatchedMinutesVOD.find((a) => {
        //         return a.assetId === s;
        //     });

        //     if (mv && pv) {
        //         let p = pv.value;
        //         let m = mv.value;
        //         let tawt = 0;

        //         // average
        //         if (p > 0) {
        //             tawt = Number(m / p);
        //         } else if (Number(m) === 0.0 || Number(p) === 0.0) {
        //             // if plays or watched minutes is 0, just set the average to 0
        //             tawt = Number(0.0);
        //         }

        //         let avg = 0;

        //         if (duration > 0) {
        //             let durationMinutes = duration / 60.0;

        //             avg = (100.0 * Number(tawt)) / Number(durationMinutes);
        //         }

        //         // round to 0 decimal
        //         let percent = Math.round(avg);

        //         return percent;
        //     }

        //     return 0;
        // };

        const result = latestAssets.map((asset, index) => {
            // console.log("thumb", asset.thumbnailSmall);
            let item = {
                index: index + 1,
                thumbnail: <img className={classes.assetImage} src={asset.thumbnailSmall + "&width=100"} alt="thumbnail" onClick={() => clickhandler(asset)} />,
                name: (
                    // <div>
                    //     <div>{asset.name}</div>
                    //     <div>user and date here</div>
                    // </div>
                    <AssetText2
                        title={
                            asset.name && asset.name.replace(/<[^>]*>?/gm, "")
                            // el.publicName[el.defaultLanguage]
                            //     ? el.publicName[el.defaultLanguage]
                            //     : el.name[el.defaultLanguage] && el.name.en_US.replace(/<[^>]*>?/gm, "")
                        }
                        onClick={() => clickhandler(asset)}
                        info={
                            asset.date ? moment(asset.date).format("MMMM Do YYYY, HH:mm ") : ""
                            // el.startTimePublic
                            //     ? moment(el.startTimePublic).format("MMMM Do YYYY, HH:mm ")
                            //     : moment(el.startTime).format("MMMM Do YYYY, HH:mm ")
                        }
                        duration={asset.durationPublic ? convertDuration(asset.durationPublic) : convertDuration(asset.duration)}
                    />
                ),
                plays: (
                    <div className={classes.AssetInfo}>
                        <div className={classes.icon}>
                            <FontAwesomeIcon icon="play" size="1x" />
                        </div>
                        {/* {asset.plays ? asset.plays : 0} */}
                        {getPlays(asset.id)}
                    </div>
                ),
                uniqueViewers: (
                    <div className={classes.AssetInfo}>
                        <div className={classes.icon}>
                            <FontAwesomeIcon icon="user" size="1x" />
                        </div>
                        {/* {asset.uniqueViewers ? asset.uniqueViewers : 0} */}
                        {getUniqueViewers(asset.id)}
                    </div>
                ),

                // avgPercentageWatched: (
                //     <div className={classes.AssetInfo}>
                //         <div className={classes.icon}>
                //             <FontAwesomeIcon icon={faCheckCircle} size="1x" />
                //         </div>
                //         {/* {asset.avgPercentageWatched ? asset.avgPercentageWatched : 0} */}
                //         {getAvgPercentageWatched(asset.id, asset.duration) + " %"}
                //     </div>
                // ),
            };
            return item;
        });

        return result;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [latestAssets, latestAssetsTotalPlaysVOD, latestAssetsTotalUniqueViewersVOD]); //, latestAssetsTotalWatchedMinutesVOD]);

    const getKpis = async (name) => {
        // console.log("-----> getKpiThisPeriod: name, organizationId, createdBy", name, organizationId, createdBy);

        if (!latestAssets) return [];

        let assetIds = latestAssets.map((asset) => asset.id);

        // console.log("getKpis: latestAsset ids", assetIds);

        let returnArray = await getKpiValuesFromOpenSearch(name, organizationId, assetIds); //, createdBy, assetId, eventId, startDateThisPeriod, endDateThisPeriod);

        // console.log("getKpis: latestAsset returnArray", returnArray);

        return returnArray;
    };

    useEffect(() => {
        const fetchData = async () => {
            const p = await getKpis("totalPlaysVOD", latestAssets);
            setLatestAssetsTotalPlaysVOD(p);
            const v = await getKpis("totalUniqueViewersVOD", latestAssets);
            setLatestAssetsTotalUniqueViewersVOD(v);
            // const m = await getKpis("totalWatchedMinutesVOD", latestAssets);
            // setLatestAssetsTotalWatchedMinutesVOD(m);
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationId, latestAssets]); //, createdBy, startISODateThisPeriod, endISODateThisPeriod, startISODateLastPeriod, endISODateLastPeriod]);

    const Table = ({ columns, data }) => {
        // Use the state and functions returned from useTable to build your UI
        const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
            columns,
            data,
        });

        // console.log("data", data);

        // Render the UI for your table
        return (
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr className={classes.resultItemTitle} {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    };

    return (
        <>
            {/* <div className={classes.Assets_dashboard_title}>
                {props.showLoader && (
                    <div className={classes.Assets_loader}>
                        {" "}
                        <Loader type="Oval" color="#394855" height={300} width={300} />
                    </div>
                )}
            </div>{" "}
            <div className={classes.Assets_dashboard_title}>
                {" "}
                {latestAssets.length > 0 && (
                    <AssetsItem
                        assets={latestAssets}
                        // .filter((event) => event.startTime < startDay && event.startTime > monthAgo)
                        // .sort((a, b) => b.startTime - a.startTime)}
                        heading={"Latest and most popular video assets"}
                        organizationId={props.organizationId}
                        showNoImage={true}
                    />
                )}{" "}
            </div>{" "} */}
            <div className={classes.row}>
                <div className={classes.column}>
                    <Table columns={columns1} data={data} />
                    {showLoader1 && (
                        <div className={classes.Assets_loader}>
                            {" "}
                            <Loader type="Oval" color="#394855" height={300} width={300} />
                        </div>
                    )}
                </div>
                {/* <div className={classes.column}>
                    <Table columns={columns2} data={data} />
                    {showLoader2 && (
                        <div className={classes.Assets_loader}>
                            {" "}
                            <Loader type="Oval" color="#394855" height={300} width={300} />
                        </div>
                    )}
                </div> */}
            </div>
        </>
    );
}
