import React, { useEffect, useState } from "react";
// import Select from "react-select";
// import Loader from "react-loader-spinner";
import { useHistory } from "react-router-dom";
import moment from "moment";

// import LatestAssets from "./Grids/LatestAssets";
import AssetListPerAccount from "./AssetListPerAccount";
// import AssetsItem from "./AssetsItem";

import classes from "./SuiteAssetList.module.css";

import { getAssetUtilityAssetInfo } from "../../scripts/dataHandlers";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SuiteAssetList = (props) => {
    // For these, null means we haven't yet got any value, these should contain arrays after a while.
    // const [latestAssets, setLatestAssets] = useState(null);
    // const [popularAssets, setPopularAssets] = useState(null);
    // const [popularAssetIds, setPopularAssetIds] = useState(null);
    const [assetList, setAssetList] = useState(null);
    const [assetsMissingVideoPresets, setAssetsMissingVideoPresets] = useState(0);
    const [assetsMissingSubtitles, setAssetsMissingSubtitles] = useState(0);
    const [assetsMissingThumbnailImages, setAssetsMissingThumbnailImages] = useState(0);
    const [assetsMissingBannerImages, setAssetsMissingBannerImages] = useState(0);
    const [assetsMissingCoverImages, setAssetsMissingCoverImages] = useState(0);

    const organizationId = props.organizationId;
    const secret = props.secret;
    const userId = props.userId;
    // const assetId = null;
    // const eventId = null;

    const history = useHistory();

    // console.log("SearchForAssets: org, user", organizationId, userId);

    // const { searchAssets, setSearchAssets } = useMyContext();
    // const { searchAssetsString, setSearchAssetsString } = useMyContext();

    //const createdBy = userId;

    console.log("SuiteAssetList: orgId, userId", organizationId, userId);

    let calculateMissingInfo = (assets) => {
        let v = 0;
        let s = 0;
        let t = 0;
        let b = 0;
        let c = 0;

        for (const asset of assets) {
            if (asset.videoFiles === 0) {
                v++;
            }
            if (asset.subtitleAssets === 0) {
                s++;
            }
            if (asset.thumbnailImages === 0) {
                t++;
            }
            if (asset.bannerImages === 0) {
                b++;
            }
            if (asset.coverImages === 0) {
                c++;
            }
        }

        setAssetsMissingVideoPresets(v);
        setAssetsMissingSubtitles(s);
        setAssetsMissingThumbnailImages(t);
        setAssetsMissingBannerImages(b);
        setAssetsMissingCoverImages(c);
    };

    useEffect(() => {
        const getAssets = async () => {
            try {
                let assets = [];
                let res = null;

                // console.log("HERE: orgId, secret, userId", organizationId, secret, userId);

                // limit is not used in the API call, it's just a place holder here
                const limit = 10;

                res = await getAssetUtilityAssetInfo(organizationId, secret, limit * 5);

                console.log("res", res);

                if (res && res.data && res.data.status === "ok") {
                    assets = res.data.assets;
                    // assets = res.data.assets.filter((asset) => Number(asset.createdBy) === Number(userId));
                    console.log("getAssetUtilityAssetInfo returned " + assets.length + " assets (limit " + limit + ")");
                }

                // show only 10 of the ones we got from the back end
                // assets = assets.slice(0, limit);

                setAssetList(assets);
                calculateMissingInfo(assets);
            } catch (error) {
                console.log(error);
            }
        };
        organizationId && getAssets();
    }, [organizationId, secret]);

    // const { searchAssets, setSearchAssets } = useMyContext();
    // const { searchAssetsString, setSearchAssetsString } = useMyContext();

    return (
        <div className={classes.view}>
            <div>
                <h2>Asset List Missing Info</h2>
                <p>Assets without video presets: {assetsMissingVideoPresets}</p>
                <p>Assets without subtitles: {assetsMissingSubtitles}</p>
                <p>Assets without thumbnail images: {assetsMissingThumbnailImages}</p>
                <p>Assets without banner images: {assetsMissingBannerImages}</p>
                <p>Assets without DVD cover images: {assetsMissingCoverImages}</p>
            </div>
            <AssetListPerAccount
                organizationId={props.organizationId}
                secret={props.secret}
                assetList={assetList}
                // showLoader={showLoader}
            />
        </div>
    );
};

export default SuiteAssetList;
